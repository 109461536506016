<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Adventure starts here 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            here we go
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">
              <!-- username -->
              <b-form-group
                :label = "$t('username')"
                label-for="register-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-form-input
                    id="register-username"
                    v-model="username"
                    name="register-username"
                    :state="errors.length > 0 ? false:null"
                    placeholder="johndoe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                :label="$t('Email')"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- phone number -->
              <b-form-group
                  :label="$t('PhoneNumber')"
                  label-for="register-phone-number"
              >
                <validation-provider
                    #default="{ errors }"
                    name="PhoneNumber"
                    rules="required|phoneNumber"
                >
                  <b-form-input
                      id="register-phone-number"
                      v-model="phoneNumber"
                      name="register-phone-number"
                      :state="errors.length > 0 ? false:null"
                      placeholder="158xxxxxxxx"
                      :validate="{ required: true, min: 10, regex: /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/ }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                :label="$t('Password')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required|password"
                  vid = "confirm"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>


              <b-form-group
                  label-for="register-password-confirmation"
                  :label="$t('Password_confirmation')"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Password_confirmation"
                    rules="required|password|confirmed:confirm"
                >
                  <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                        id="register-password-confirmation"
                        v-model="password_confirmation"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false:null"
                        name="register-password-confirmation"
                        placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>


              <b-form-group>
                  <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="status"
                    name="checkbox-1"
                    value ="accepted"
                    unchecked-value ="unaccepted"
                  >
                    {{ $t('I agree to') }}
                    <b-link>{{ $t('privacy policy & terms') }}</b-link>
                  </b-form-checkbox>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                @click.prevent="validationForm"
              >
                {{ $t('Sign up') }}
              </b-button>
              <small v-if ="!privacy_read" class="text-danger">{{ $t('Please read the privacy policy & terms') }}</small>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>{{ $t('Already have an account ?') }}</span>
            <b-link :to="{name:'auth-login-v2'}">
              <span>&nbsp;{{ $t('Sign in instead') }}</span>
            </b-link>
          </p>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">
              {{ $t('or') }}
            </div>
          </div>

          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email, phoneNumber } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useJwt from '@/auth/jwt/useJwt'



export default {
  "components": {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  "mixins": [togglePasswordVisibility],
  "data"() {
    return {
      "privacy_read": true,
      "status": '',
      "username": '',
      "userEmail": '',
      "userPhoneNumber": '',
      "password": '',
      "password_confirmation": '',
      "sideImg": require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      phoneNumber
    }
  },
  "computed": {
    "passwordToggleIcon"() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    "imgUrl"() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  "setup"(){
    const { t } = useI18nUtils()
    return {
      t
    }
  },
  "methods": {
      validationForm() {
        if (this.status === 'accepted') {
          this.privacy_read = true
          this.$refs.registerForm.validate().then(success => {
            if (success) {
              useJwt.register({
                username: this.username,
                phone_number: this.phoneNumber,
                email: this.userEmail,
                password1: this.password,
                password2: this.password,
                role: "client",
              })
                  .then(response => {
                    const userData = response.data.user
                    useJwt.setToken(response.data.token)
                    userData['ability'] = [
                      {
                        action: 'manage',
                        subject: 'all',
                      },
                    ]
                    localStorage.setItem('userData', JSON.stringify(userData))
                    this.$ability.update(userData.ability)
                    this.$router.push('/')
                  })
                  .catch(error => {
                    this.$refs.registerForm.setErrors(error.response.data.error)
                  })
            }
          })
        }
      else {
          this.privacy_read = false
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
